<template>
    <div class="bd-navbar-nav">
        <ul class="nav">
            <li class="nav-item" v-for="(route, index) in routes" :key="index" :class="route.cls">
                <a :href="route.path" class="nav-link">
                    <span v-if="route.icon" v-html="route.icon"></span>
                    <span class="nav-text">{{route.name}}</span>

                    <svg v-if="route.children" class="nav-arrow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#75787B" stroke-width="1.5"><path d="M10 16L14 12L10 8"></path></svg>
                </a>

                <ul v-if="route.children" class="nav-sub">
                    <li v-for="(sub, i) in route.children" :key="i" class="nav-sub-item">

                        <a :href="sub.path" class="nav-sub-link">
                            <span>{{ sub.name }}</span>
                            <svg v-if="sub.children" class="nav-sub-arrow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#75787B" stroke-width="1.5"><path d="M10 16L14 12L10 8"></path></svg>
                        </a>

                        <ul class="nav-sub-sub" v-if="sub.children">
                            <li v-for="(item, n) in sub.children" :key="n" class="nav-sub-item">
                                <a :href="item.path" class="nav-sub-link">{{ item.name }}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>

            <li class="nav-item search ml-lg-3">
                <form method="get" action="/search">
                    <input
                        type="text"
                        class="form-control"
                        name="q"
                        placeholder="Search..."
                    />
                </form>
            </li>
        </ul>
    </div>
</template>

<script>

import {routes} from './routes';

export default {
    data(){
        return {
            routes: routes,
        }
    },

};
</script>

<style lang="scss" scoped>

    .bd-navbar-nav{
        .nav-item{
            position: relative;

            &:hover{
                .nav-sub{
                     opacity: 1;
                     visibility: unset;
                }
            }

            .nav-arrow{
                transform: rotate(90deg);
            }
        }
    }

    .nav-sub{
        top: 100%;
        position: absolute;
        background: #ffffff;
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
        opacity: 0;
        visibility: hidden;
        z-index: 300;
        min-width: 220px;
        border-radius: 8px;
        padding-top: 8px;
        padding-bottom: 8px;

        .nav-sub-item{
            position: relative;

            &:hover{
                .nav-sub-sub{
                    opacity: 1;
                    visibility: unset;
                }
            }
        }

        .nav-sub-item + .nav-sub-item{
            border-top: 1px solid rgba(0,0,0,.03);
        }

        .nav-sub-link{
            padding: 0.5rem 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            white-space: nowrap;

            &:hover{
                color: #16181b;
                text-decoration: none;
                background-color: #f8f9fa;
            }
        }
    }
    .nav-sub-sub{
        left: 100%;
        position: absolute;
        top: 0;

        background: #ffffff;
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);

        opacity: 0;
        visibility: hidden;
        min-width: 220px;
        border-radius: 8px;
        padding-top: 8px;
        padding-bottom: 8px;
    }
</style>
