<template>
<div>

    <div class="regis-title mb-4 d-flex align-items-center">
        <h4>Member Account</h4>
    </div>

    <div class="row mb-4 justify-content-center">
    <div class="col-lg-9">

        <div class="card border-0">
            <div class="">
                <div class="mb-3">
                    <label class="font-weight-bold" for="email" v-on:click="easter"><span class="font-weight-bold">Email / อีเมลสมาชิก</span> <span class="text-danger">*</span></label>
                    <input type="email" id="email" class="form-control" v-model="member.email" :class="errors.email && 'is-invalid'" autocomplete="off" />
                    <div class="mt-1 d-flex text-black-50 fs-13"><svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24"><path d="M18.016,11.9997 L18.016,10.9997 L20.016,10.9997 L20.016,11.9997 L18.016,11.9997 Z M10.985,4.9997 L10.985,2.9997 L11.985,2.9997 L11.985,4.9997 L10.985,4.9997 Z M4,12.001 L4,11 L6,11 L6,12.001 L4,12.001 Z M5,5.70781389 L5.70781389,5 L7.12202745,6.41421356 L6.41421356,7.12202745 L5,5.70781389 Z M17.6017864,7.12202745 L16.8939725,6.41421356 L18.3081861,5 L19.016,5.70781389 L17.6017864,7.12202745 Z M11.9842,6.9999 C14.1895333,6.9999 15.9842,8.79456667 15.9842,10.9999 C15.9842,12.3776778 15.0005493,13.9960327 13.9992676,14.5 L14.008842,17.0307987 C14.0109172,18.0280521 13.2214864,18.9786987 11.993042,18.9786987 C10.7645975,18.9786987 10.008842,18.0306767 10.008842,17.0307987 L10,14.5 C9.01538086,13.9793701 7.9842,12.3794556 7.9842,10.9999 C7.9842,8.79456667 9.77886667,6.9999 11.9842,6.9999 Z M13,17.1676025 L12.9842,14.9999 L10.9855324,14.9999 L11,17.1676025 C11,17.7192348 11.5174479,18.0044759 12.0158,18.0008138 C12.5141521,17.9971517 12.9986676,17.7192348 13,17.1676025 Z M11.9842,7.9999 C10.3299143,7.9999 8.9842,9.34561429 8.9842,10.9999 C8.9842,11.8390429 9.33305714,12.5941857 9.89105714,13.1367571 C10.1267714,13.3656143 10.4473429,13.4856143 10.6247714,13.7676143 C10.6573429,13.8190429 10.6770571,13.9039 10.7002,13.9999 L13.2802,13.9999 C13.2930571,13.8979 13.3136286,13.8070429 13.3470571,13.7539 C13.5193429,13.4804714 13.8270571,13.3750429 14.0584857,13.1547571 C14.6276286,12.6113286 14.9842,11.8484714 14.9842,10.9999 C14.9842,9.34561429 13.6384857,7.9999 11.9842,7.9999 Z"></path></svg> <div class="ml-2">กรุณากรอกอีเมล์ที่ใช้งานเป็นประจำ เพื่อประโยขน์สูงสุดของสมาชิก</div></div>
                    <div class="invalid-feedback" v-if="errors.email">{{ errors.email }}</div>
                </div>

                <div class="mb-3">
                    <label class="font-weight-bold" for="password"><span class="">Password / รหัสผ่าน</span> <span class="text-danger">*</span></label>
                    <input type="password" id="password" class="form-control" v-model="member.password" :class="errors.password && 'is-invalid'" autocomplete="off" />
                    <div class="mt-1 d-flex text-black-50 fs-13"><svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24"><path d="M18.016,11.9997 L18.016,10.9997 L20.016,10.9997 L20.016,11.9997 L18.016,11.9997 Z M10.985,4.9997 L10.985,2.9997 L11.985,2.9997 L11.985,4.9997 L10.985,4.9997 Z M4,12.001 L4,11 L6,11 L6,12.001 L4,12.001 Z M5,5.70781389 L5.70781389,5 L7.12202745,6.41421356 L6.41421356,7.12202745 L5,5.70781389 Z M17.6017864,7.12202745 L16.8939725,6.41421356 L18.3081861,5 L19.016,5.70781389 L17.6017864,7.12202745 Z M11.9842,6.9999 C14.1895333,6.9999 15.9842,8.79456667 15.9842,10.9999 C15.9842,12.3776778 15.0005493,13.9960327 13.9992676,14.5 L14.008842,17.0307987 C14.0109172,18.0280521 13.2214864,18.9786987 11.993042,18.9786987 C10.7645975,18.9786987 10.008842,18.0306767 10.008842,17.0307987 L10,14.5 C9.01538086,13.9793701 7.9842,12.3794556 7.9842,10.9999 C7.9842,8.79456667 9.77886667,6.9999 11.9842,6.9999 Z M13,17.1676025 L12.9842,14.9999 L10.9855324,14.9999 L11,17.1676025 C11,17.7192348 11.5174479,18.0044759 12.0158,18.0008138 C12.5141521,17.9971517 12.9986676,17.7192348 13,17.1676025 Z M11.9842,7.9999 C10.3299143,7.9999 8.9842,9.34561429 8.9842,10.9999 C8.9842,11.8390429 9.33305714,12.5941857 9.89105714,13.1367571 C10.1267714,13.3656143 10.4473429,13.4856143 10.6247714,13.7676143 C10.6573429,13.8190429 10.6770571,13.9039 10.7002,13.9999 L13.2802,13.9999 C13.2930571,13.8979 13.3136286,13.8070429 13.3470571,13.7539 C13.5193429,13.4804714 13.8270571,13.3750429 14.0584857,13.1547571 C14.6276286,12.6113286 14.9842,11.8484714 14.9842,10.9999 C14.9842,9.34561429 13.6384857,7.9999 11.9842,7.9999 Z"></path></svg> <div class="ml-2">รหัสผ่านประกอบด้วยตัวอักษร และตัวเลขที่มีความยาว 6-12 ตัวอักษร</div></div>
                    <div class="invalid-feedback" v-if="errors.password">{{ errors.password }}</div>
                </div>

                <div class="mb-3">
                    <label class="font-weight-bold" for="password_confirmation"><span class="font-weight-bold">Confirm Password / ยืนยันรหัสผ่าน</span> <span class="text-danger">*</span></label>
                    <input type="password" id="password_confirmation" class="form-control" v-model="member.password_confirmation" :class="errors.password_confirmation && 'is-invalid'" autocomplete="off" />
                    <div class="invalid-feedback" v-if="errors.password_confirmation">{{ errors.password_confirmation }}</div>
                </div>
            </div>
        </div>
    </div>
</div>


    <div class="regis-title mb-4 d-flex align-items-center">
        <h4>Member Profile</h4>
    </div>
    <div class="row mb-4 justify-content-center">

        <div class="col-lg-9">

            <div class="card border-0">
                <div class="">

                    <div class="row">
                        <div class="mb-3 col-md-4">
                            <label class="font-weight-bold" for="">Title / คำนำหน้า (ภาษาไทย)</label>

                            <div :class="member.prefix===0 && 'input-group'">
                                <select class="custom-select" v-model="member.prefix" :class="errors.prefix && 'is-invalid'">
                                    <option :value="item.id" v-for="(item, n) in prefix" :key="n">{{ item.name }}</option>
                                </select>

                                <input v-if="member.prefix===0" type="text" class="form-control" v-model="member.prefix_custom" :class="errors.prefix_custom && 'is-invalid'" style="min-width: 150px;" />
                            </div>

                            <div class="invalid-feedback d-block" v-if="errors.prefix">{{ errors.prefix }}</div>
                        </div>
                        <div class="mb-3 col-md-4">
                            <label class="font-weight-bold" for="">Name / ชื่อ (ภาษาไทย)<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="member.first_name" :class="errors.first_name && 'is-invalid'" />
                            <div class="invalid-feedback" v-if="errors.first_name">{{ errors.first_name }}</div>
                        </div>
                        <div class="mb-3 col-md-4">
                            <label class="font-weight-bold" for="">Surname / นามสกุล (ภาษาไทย)<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="member.last_name" :class="errors.last_name && 'is-invalid'" />
                            <div class="invalid-feedback" v-if="errors.last_name">{{ errors.last_name }}</div>
                        </div>
                    </div>

                    <div class="row">

                        <div class="mb-3 col-md-4">

                        </div>
                        <div class="mb-3 col-md-4">
                            <label class="font-weight-bold" for="first_name_en">Name / ชื่อ (ภาษาอังกฤษ)<span class="text-danger">*</span></label>
                            <input id="first_name_en" type="text" class="form-control" v-model="member.first_name_en" :class="errors.first_name_en && 'is-invalid'" />
                            <div class="invalid-feedback" v-if="errors.first_name_en">{{ errors.first_name_en }}</div>
                        </div>
                        <div class="mb-3 col-md-4">
                            <label class="font-weight-bold" for="last_name_en">Surname / นามสกุล (ภาษาอังกฤษ) <span class="text-danger">*</span></label>
                            <input id="last_name_en" type="text" class="form-control" v-model="member.last_name_en" :class="errors.last_name_en && 'is-invalid'" />
                            <div class="invalid-feedback" v-if="errors.last_name_en">{{ errors.last_name_en }}</div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="mb-3" :class="member.prefix===0 ? 'col-md-4': 'col-md-2'"></div>
                        <div class="mb-3" :class="member.prefix===0 ? 'col-md-4': 'col-md-5'">
                            <label class="font-weight-bold" for="first_name_en">Name / ชื่อ (ภาษาอังกฤษ)<span class="text-danger">*</span></label>
                            <input id="first_name_en" type="text" class="form-control" v-model="member.first_name_en" :class="errors.first_name_en && 'is-invalid'" />
                            <div class="invalid-feedback" v-if="errors.first_name_en">{{ errors.first_name_en }}</div>
                        </div>
                        <div class="mb-3" :class="member.prefix===0 ? 'col-md-4': 'col-md-5'">
                            <label class="font-weight-bold" for="last_name_en">Surname / นามสกุล (ภาษาอังกฤษ) <span class="text-danger">*</span></label>
                            <input id="last_name_en" type="text" class="form-control" v-model="member.last_name_en" :class="errors.last_name_en && 'is-invalid'" />
                            <div class="invalid-feedback" v-if="errors.last_name_en">{{ errors.last_name_en }}</div>
                        </div>
                    </div>

                    <div class="mb-3">
                        <label class="font-weight-bold" for="gender">Gender / เพศ <span class="text-danger">*</span></label>
                        <div class="d-md-flex radio-wrap" :class="errors.gender && 'is-error'">
                            <div class="custom-control custom-radio mb-2 mb-md-0 mr-md-3">
                                <input type="radio" name="gender" id="gender_1" value="1" class="custom-control-input" v-model="member.gender" />
                                <label for="gender_1" class="custom-control-label">Male (ชาย)</label>
                            </div>
                            <div class="custom-control custom-radio mb-2 mb-md-0 mr-md-3">
                                <input type="radio" name="gender" id="gender_2" value="2" class="custom-control-input" v-model="member.gender" />
                                <label for="gender_2" class="custom-control-label">Female (หญิง)</label>
                            </div>
                            <div class="custom-control custom-radio mr-md-3">
                                <input type="radio" name="gender" id="gender_0" value="0" class="custom-control-input" v-model="member.gender" />
                                <label for="gender_0" class="custom-control-label">Not specific (ไม่ระบุ)</label>
                            </div>
                        </div>
                        <div class="invalid-feedback d-block" v-if="errors.gender">{{ errors.gender }}</div>
                    </div>

                    <div class="mb-3">
                        <label class="font-weight-bold" for="">Date of Birth / วันเกิด <span class="text-danger">*</span></label>

                        <div class="row">
                            <div class="col-md-9">
                                <div class="row">
                                    <div class="col-md-3 mb-1 mb-md-0">
                                        <select class="custom-select" v-model="member.birthdate.date" :class="errors.birthdate && 'is-invalid'">
                                            <option value="">วันที่</option>
                                            <option :value="day.id" v-for="day in days" :key="day.id">{{ day.name }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4 mb-1 mb-md-0">
                                        <select class="custom-select" v-model="member.birthdate.month" :class="errors.birthdate && 'is-invalid'">
                                            <option value="">เดือน</option>
                                            <option :value="month.id" v-for="month in months" :key="month.id">{{ str_month(month.name-1) }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4">
                                        <select class="custom-select" v-model="member.birthdate.year" :class="errors.birthdate && 'is-invalid'">
                                            <option value="">ปี</option>
                                            <option :value="year.id" v-for="year in years" :key="year.id">{{ year.name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="invalid-feedback d-block" v-if="errors.birthdate">{{ errors.birthdate }}</div>
                    </div>

                    <div class="mb-1">
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <div class="custom-control custom-radio mb-2">
                                    <input type="radio" name="show_idcrad" id="show_idcrad-1" class="custom-control-input" value="1" v-model="member.show_idcrad" />
                                    <label for="show_idcrad-1" class="custom-control-label font-weight-bold">Identification No. / หมายเลขบัตรประจำตัว <span v-if="member.show_idcrad==1" class="text-danger">*</span></label>
                                </div>

                                <input type="text" class="form-control" :disabled="member.show_idcrad!=1" v-model="member.idcrad" :class="errors.idcrad && 'is-invalid'" />
                                <div class="invalid-feedback d-block" v-if="errors.idcrad">{{ errors.idcrad }}</div>
                            </div>
                            <div class="col-md-6 mb-3">

                                <div class="custom-control custom-radio mb-2">
                                    <input type="radio" name="show_idcrad" id="show_idcrad-2" class="custom-control-input" value="2" v-model="member.show_idcrad" />
                                    <label for="show_idcrad-2" class="custom-control-label font-weight-bold">Passport / หมายเลขพาสปอร์ต <span v-if="member.show_idcrad==2" class="text-danger">*</span></label>
                                </div>

                                <input type="text" class="form-control" :disabled="member.show_idcrad!=2" v-model="member.passport" :class="errors.passport && 'is-invalid'" />

                                <div class="invalid-feedback d-block" v-if="errors.passport">{{ errors.passport }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <label class="font-weight-bold" for="">Mobile No. / เบอร์ติดต่อ
                                <span class="text-danger">*</span></label>
                            <input type="tel" class="form-control" v-model="member.tel_mobile" :class="errors.tel_mobile && 'is-invalid'" />
                            <div class="invalid-feedback" v-if="errors.tel_mobile">{{ errors.tel_mobile }}</div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="font-weight-bold" for="">Facebook / ชื่อเฟสบุ๊ค </label>
                            <input type="text" class="form-control" v-model="member.facebook" />
                        </div>
                    </div>

                    <div class="mb-3">
                        <label class="font-weight-bold" for="">Occupation or Poition / อาชีพหรือตำแหน่ง <span class="text-danger">*</span></label>
                        <select class="custom-select" v-model="member.occupation_id" :class="errors.occupation_id && 'is-invalid'" @change="HandlerOccupation">
                            <option value="">Please select / โปรดเลือก</option>
                            <option :value="occupation.id" v-for="occupation in occupations" :key="occupation.id">{{ occupation.name }}</option>
                        </select>
                        <div class="invalid-feedback" v-if="errors.occupation_id">{{ errors.occupation_id }}</div>
                    </div>

                    <!-- 1-8 -->
                    <div v-if="member.occupation_id>=1 && member.occupation_id<=8">
                        <div>
                            <label class="font-weight-bold" for="">Affiliation / สังกัด</label>

                            <select class="custom-select" v-model="member.affiliation_id" :class="errors.affiliation_id && 'is-invalid'">
                                <option value="">Please select / โปรดเลือก</option>
                                <option :value="affiliation.id" v-for="affiliation in affiliations" :key="affiliation.id">{{ affiliation.name }}</option>
                            </select>

                            <div class="mt-1" v-if="member.affiliation_id==8">
                                <label class="font-weight-bold mb-0 text-nowrap" for="affiliation_custom">Please specify / โปรดระบุสังกัด <span class="text-danger">*</span></label>
                                <input type="text" id="affiliation_custom" class="form-control" v-model="member.affiliation_custom" :class="errors.affiliation_custom && 'is-invalid'" />
                                <div class="invalid-feedback" v-if="errors.affiliation_custom">{{ errors.affiliation_custom }}</div>
                            </div>

                            <div class="invalid-feedback" v-if="errors.affiliation_id">{{ errors.affiliation_id }}</div>
                        </div>

                        <div class="mt-3">
                            <label class="checkbox-wrap" for="" :class="errors.teachings && 'is-error'">
                                <div class="font-weight-bold">Teaching Level / ระดับชั้นที่สอนหรือเกี่ยวข้อง <span class="text-danger">*</span></div>
                                <div class="text-muted">(Can select more than 1 choice / สามารถเลือกได้มากกว่า 1 ข้อ)</div>
                            </label>

                            <div class="row">
                                <div class="mb-2" :class="n%2 ? 'col-md-7':'col-md-5'" v-for="(teaching, n) in teachings" :key="teaching.id">
                                    <div class="custom-control custom-checkbox">
                                        <input
                                            type="checkbox"
                                            name="teaching"
                                            :id="`teaching-`+teaching.id"
                                            class="custom-control-input"
                                            :value="teaching.id"
                                            v-model="member.teachings"
                                        />
                                        <label :for="`teaching-`+teaching.id" class="custom-control-label">{{teaching.name}}</label>
                                    </div>
                                </div>
                            </div>

                            <div v-if="member.teachings.indexOf(7)>=0">
                                <label class="font-weight-bold mb-0 text-nowrap" for="other_teaching_group">Please specify / โปรดระบุระดับชั้นที่สอนหรือเกี่ยวข้อง <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" id="other_teaching_group" v-model="member.other_teaching_group" :class="errors.other_teaching_group && 'is-invalid'" />
                                <div class="invalid-feedback" v-if="errors.other_teaching_group">{{ errors.other_teaching_group }}</div>
                            </div>

                            <div class="invalid-feedback d-block" v-if="errors.teachings">{{ errors.teachings }}</div>
                        </div>

                        <div class="mt-3">
                            <label class="checkbox-wrap" for="" :class="errors.levels && 'is-error'">
                                <div class="font-weight-bold">Subject or Related Field / กลุ่มสาระที่สอนหรือทำงานเกี่ยวข้อง <span class="text-danger">*</span></div>
                                <div class="text-muted">(Can select more than 1 choice / สามารถเลือกได้มากกว่า 1 ข้อ)</div>
                            </label>

                            <div class="row">
                                <div class="mb-2" :class="n%2 ? 'col-md-7':'col-md-5'" v-for="(subject, n) in subjects" :key="subject.id">
                                    <div class="custom-control custom-checkbox">
                                        <input
                                            type="checkbox"
                                            name="subject"
                                            :id="`subject-`+subject.id"
                                            class="custom-control-input"
                                            :value="subject.id"
                                            v-model="member.levels"
                                        />
                                        <label :for="`subject-`+subject.id" class="custom-control-label">{{subject.name}}</label>
                                    </div>

                                    <div v-if="subject.id==14 && foreignLanguage" class="mt-1">
                                        <input type="text" class="form-control" placeholder="Please specify / โปรดระบุภาษา *" v-model="member.foreign_language" :class="errors.foreign_language && 'is-invalid'">
                                        <div class="invalid-feedback d-block" v-if="errors.foreign_language">{{ errors.foreign_language }}</div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="member.levels.indexOf(15)>=0">
                                <label class="font-weight-bold mb-0 text-nowrap" for="other_teaching_level">Please specify / โปรดระบุกลุ่มสาระที่สอนหรือทำงานเกี่ยวข้อง <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" id="other_teaching_level" v-model="member.other_teaching_level" :class="errors.other_teaching_level && 'is-invalid'" />
                                <div class="invalid-feedback" v-if="errors.other_teaching_level">{{ errors.other_teaching_level }}</div>
                            </div>

                            <div class="invalid-feedback d-block" v-if="errors.levels">{{ errors.levels }}</div>
                        </div>


                        <div class="mt-3 row">
                            <div class="col-md-4 mb-3">
                                <label class="font-weight-bold" for="">Office Address / สถานที่ทำงาน <span class="text-danger">*</span></label>

                                <select class="custom-select" v-model="member.work_id" :class="errors.work_id && 'is-invalid'">
                                    <option value="">Please select / โปรดเลือก</option>
                                    <option :value="address.id" v-for="address in officeAddress" :key="address.id">{{ address.name }}</option>
                                </select>

                                <div class="invalid-feedback" v-if="errors.work_id">{{ errors.work_id }}</div>
                            </div>
                            <div class="col-md-8 mb-3">
                                <label class="font-weight-bold" for="">School or Institution / ชื่อสถาบัน  <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" v-model="member.work_name" :class="errors.work_name && 'is-invalid'" />
                                <div class="invalid-feedback" v-if="errors.work_name">{{ errors.work_name }}</div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4 mb-3">
                                <label class="font-weight-bold" for="">Province / จังหวัด  <span class="text-danger">*</span></label>

                                <select class="custom-select" v-model="member.work_province_id" :class="errors.work_province_id && 'is-invalid'" :disabled="provinces.length==0">

                                    <option value="">Please select / โปรดเลือก</option>
                                    <option :value="province.id" v-for="province in provinces" :key="province.id">{{ province.name_en }} ({{ province.name_th }})</option>
                                </select>
                                <div class="invalid-feedback" v-if="errors.work_province_id">{{ errors.work_province_id }}</div>
                            </div>

                            <div class="col-md-4 mb-3">
                                <label class="font-weight-bold" for="">District / อำเภอหรือเขต <span class="text-danger">*</span></label>

                                <select class="custom-select" v-model="member.work_amphure_id" :disabled="amphures.length==0" :class="errors.work_amphure_id && 'is-invalid'">

                                    <option value="">Please select / โปรดเลือก</option>
                                    <option :value="amphure.id" v-for="amphure in amphures" :key="amphure.id">{{ amphure.name_en }} ({{ amphure.name_th }})</option>
                                </select>

                                <div class="invalid-feedback" v-if="errors.work_amphure_id">{{ errors.work_amphure_id }}</div>
                            </div>

                            <div class="col-md-4 mb-3">
                                <label class="font-weight-bold" for="">Sub District / ตำบลหรือแขวง <span class="text-danger">*</span></label>

                                <select class="custom-select" v-model="member.work_district_id" :disabled="districts.length==0" :class="errors.work_district_id && 'is-invalid'">

                                    <option value="">Please select / โปรดเลือก</option>
                                    <option :value="district.id" v-for="district in districts" :key="district.id">{{ district.name_en }} ({{ district.name_th }})</option>
                                </select>

                                <div class="invalid-feedback" v-if="errors.work_district_id">{{ errors.work_district_id }}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 mb-3">
                                <label class="font-weight-bold" for="">Postal Code / รหัสไปรษณีย์ <span class="text-danger">*</span></label>
                                <input type="tel" class="form-control" v-model.number="member.work_zip" :class="errors.work_zip && 'is-invalid'" maxlength="5" />
                                <div class="invalid-feedback" v-if="errors.work_zip">{{ errors.work_zip }}</div>
                            </div>
                        </div>
                    </div>

                    <!-- 9 -->
                    <div v-if="member.occupation_id==9">

                        <div class="row align-items-center">
                            <div class="col-md-5 mb-3">
                                <label class="font-weight-bold mb-0 text-nowrap" for="">Faculty / คณะ <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" v-model="member.university_faculty" :class="errors.university_faculty && 'is-invalid'" />
                                <div class="invalid-feedback" v-if="errors.university_faculty">{{ errors.university_faculty }}</div>
                            </div>

                            <div class="col-md-5 mb-3">
                                <label class="font-weight-bold mb-0 text-nowrap" for="">University / มหาวิทยาลัย <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" v-model="member.university_name" :class="errors.university_name && 'is-invalid'" />
                                <div class="invalid-feedback" v-if="errors.university_name">{{ errors.university_name }}</div>
                            </div>

                            <div class="col-md-2 mb-3">
                                <label class="font-weight-bold mb-0 text-nowrap" for="">Year / ชั้นปี <span class="text-danger">*</span></label>
                                <input type="tel" class="form-control" v-model="member.university_year" :class="errors.university_year && 'is-invalid'" maxlength="2" />
                                <div class="invalid-feedback" v-if="errors.university_year">{{ errors.university_year }}</div>
                            </div>
                        </div>
                    </div>
                    <!-- end: 9 -->

                    <!-- 10 or 11 -->
                    <div class="mb-3" v-if="member.occupation_id==11 || member.occupation_id==10">
                        <label class="font-weight-bold mb-0 text-nowrap" for="">Please specify / โปรดระบุอาชีพ <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" v-model="member.occupation_custom" :class="errors.occupation_custom && 'is-invalid'" />
                        <div class="invalid-feedback" v-if="errors.occupation_custom">{{ errors.occupation_custom }}</div>
                    </div>
                    <!-- end: 10 -->
                </div>
            </div>
        </div>
    </div>


    <div class="regis-title mb-4 d-flex align-items-center">
        <h4>Communication Channel</h4>
    </div>
    <div class="row mb-4 justify-content-center">

        <div class="col-lg-9">

            <div class="card border-0">
                <div class="">
                    <div>
                        <label class="font-weight-bold" for="">Newsletter Subscription / การรับข่าวสารจาก
                            EDUCA</label>

                        <div class="custom-control custom-radio mr-3">
                            <input type="radio" name="subscription" id="subscription-1" value="1" class="custom-control-input" v-model="member.subscription" />
                            <label for="subscription-1" class="custom-control-label">Newsletter Subscription / ต้องการรับข่าวสาร</label>
                        </div>

                        <div class="custom-control custom-radio mr-3">
                            <input type="radio" name="subscription" id="subscription-2" value="2" class="custom-control-input" v-model="member.subscription" />
                            <label for="subscription-2" class="custom-control-label">I prefer not to receive newsletter / ไม่ต้องการรับข่าวสาร</label>
                        </div>
                    </div>

                    <div class="mt-3" v-if="member.subscription==1">
                        <label for="">
                            <div class="font-weight-bold">
                                Newsletter Channel / ช่องทางการรับข่าวสาร
                            </div>
                            <div class="text-muted">
                                (Can select more then 1 choice /
                                สามารถเลือกได้มากกว่า 1 ข้อ)
                            </div>
                        </label>

                        <div class="d-flex">
                            <div class="custom-control custom-checkbox mr-3" v-for="channel in channels" :key="channel.id">
                                <input
                                    type="checkbox"
                                    name="channel"
                                    :id="`channel-`+channel.id"
                                    class="custom-control-input"
                                    :value="channel.id"
                                    v-model="member.channel"
                                />
                                <label :for="`channel-`+channel.id" class="custom-control-label">{{channel.name}}</label>
                            </div>
                        </div>

                        <div class="invalid-feedback d-block" v-if="errors.channel">{{ errors.channel }}</div>
                    </div>

                    <div class="mb-3 mt-3" v-if="show_lineid">
                        <label class="font-weight-bold mb-0 text-nowrap" for="lineid">ID Line <span class="text-danger">*</span></label>
                        <input id="lineid" type="text" class="form-control" v-model="member.lineid" :class="errors.lineid && 'is-invalid'" />
                        <div class="invalid-feedback" v-if="errors.lineid">{{ errors.lineid }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="regis-title mb-4 d-flex align-items-center">
        <h4>Privacy Policy</h4>
    </div>
    <div class="row mb-4  justify-content-center">

        <div class="col-lg-9">

            <div class="card border-0">
                <div class="">
                    <div class="">
                        <div class="custom-control custom-checkbox mr-3 font-weight-bold">
                            <input
                                type="checkbox"
                                name="privacy"
                                id="privacy-policy"
                                value="1"
                                class="custom-control-input"
                                v-model="member.agree"
                            />
                            <label for="privacy-policy" class="custom-control-label">I agree with</label>
                            <a href="https://educathai.com/privacy-policy" target="_blank" class="text-primary"><u>Term and Conditions</u></a> / ยอมรับ<a href="https://educathai.com/privacy-policy"  target="_blank" class="text-primary text-underline"><u>ข้อกำหนดและเงื่อนไข</u></a>
                        </div>
                        <div class="invalid-feedback d-block" v-if="errors.agree">{{ errors.agree }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row my-4 justify-content-center">

        <div class="col-lg-9">
            <div class="d-flex justify-content-center py-5">
                <a href="javascript:history.back()" class="btn btn-light btn-lg w-min-120 font-weight-bold mr-5 text-white">Cancel</a>
                <button class="btn btn-primary btn-lg w-min-120 font-weight-bold" @click="submit" :disabled="loading" :class="Object.keys(errors).length>0 && 'btn-error'">Next</button>
            </div>
        </div>
    </div>

</div>
</template>

<script>
export default {
    props: [
        'member',

        'occupations',
        'affiliations',
        'teachings',
        'subjects',
        'officeAddress',
        'provinces',

        'channels',
        'prefix',
    ],
    data(){

        let days = [];
        for (let d = 1; d <= 31; d++) {
            const day = d < 10 ? '0'+d: d;
            days.push({id: day, name:d})
        }

        let months = [];
        for (let m = 1; m <= 12; m++) {
            const month = m < 10 ? '0'+m: m;
            months.push({id: month, name:m})
        }

        const now = new Date();
        const startYear = now.getFullYear();
        const endYear = 1940; // now.getFullYear() - 60;
        let years = [];

        // console.log( startYear, endYear );

        for (let y = startYear; y >= endYear; y--) {
            const year = y < 10 ? '0'+y: y;
            years.push({id: year, name:y})
        }


        return{
            days: days,
            months: months,
            years: years,

            errors: {},
            loading: false,

            cache: {},

            // provinces: [],
            amphures: [],
            districts: [],

            ForeignLanguage: false,

            show_lineid: false,
        }

    },

    created(){

        // console.log( 'created... Member', this.member.work_province_id );

        if( this.member.work_province_id ){
            this.setProvince();
        }

        if( this.member.work_amphure_id ){
            this.setDistrict();
        }

        if( this.member.work_district_id ){
            this.setSubDistrict();
        }
        //
        // this.getProvince();
    },

    computed: {
        // _getIdcrad(){

        //     if( this.member.show_idcrad ){
        //         return true;
        //     }
        //     else {

        //         if( this.member.is_passport ){
        //             return false;
        //         }
        //         else{
        //             return true;
        //         }
        //     }
        // },
    },

    watch:{
        'member.show_idcrad'(){

            // console.log( this.member.show_idcrad );

            // if( this.member.show_idcrad=='show_idcrad' ){

            // }

            // if( !this.member.show_idcrad ){

            //     if( this.member.idcrad ){
            //         this.cache.idcrad = this.member.idcrad;
            //     }

            //     this.member.idcrad = '';
            // }
            // else if( this.cache.idcrad ){
            //     this.member.idcrad = this.cache.idcrad;
            //     delete this.cache.idcrad
            // }
        },

        'member.affiliation_id'(){
            if( this.member.affiliation_id==8 ){

                if( this.cache.affiliation_custom ){
                    this.member.affiliation_custom = this.cache.affiliation_custom;
                }

                // setTimeout(() => {
                //     const $input = $(':input#affiliation_custom');

                //     if( $input.length ){
                //         $input.focus();
                //     }
                // }, 100);
            }
            else{

                if( this.member.affiliation_custom ){
                    this.cache.affiliation_custom = this.member.affiliation_custom
                }

                this.member.affiliation_custom = '';
            }
        },

        'member.teachings'(){
            if( this.member.teachings.indexOf(7)>=0 ){

                if( this.cache.other_teaching_group ){
                    this.member.other_teaching_group = this.cache.other_teaching_group;
                }
            }
            else{
                if( this.member.other_teaching_group ){
                    this.cache.other_teaching_group = this.member.other_teaching_group
                }
                this.member.other_teaching_group = '';
            }
        },

        'member.levels'(){

            if( this.member.levels.indexOf(15)>=0 ){

                if( this.cache.other_teaching_level ){
                    this.member.other_teaching_level = this.cache.other_teaching_level;
                }
            }
            else{

                if( this.member.other_teaching_level ){
                    this.cache.other_teaching_level = this.member.other_teaching_level
                }

                this.member.other_teaching_level = '';
            }

            const isForeignLanguage = this.member.levels.find(n=>n==14);
            this.foreignLanguage = isForeignLanguage ? true: false;
        },

        'member.work_province_id'(){
            this.setProvince();
        },

        'member.work_amphure_id'(){
            this.setDistrict();
        },

        'member.work_district_id'(){
            this.setSubDistrict();
        },


    },

    methods: {

        submit(e) {
            const vm  = this;
            e.preventDefault();

            vm.errors = {}
            vm.loading = true
            // vm.request = {}

            setTimeout(() => {

                let dataForm = vm.member;

                try {
                    axios.post("/api/members/validation", vm.member)
                    .then(response => {
                        vm.loading = false

                        if( response.data.status == 200 ){
                            vm.$emit('update', vm.member)
                        }
                        else{

                            vm.$swal(
                                'เกิดข้อผิดพลาด!',
                                'กรุณาลองใหม่อีกครั้ง!',
                                'error'
                            );
                        }

                        // console.log("response: ", response.data.status)
                        // do something about response
                    })
                    .catch(error => {

                        vm.loading = false

                        if( error.response ){

                            if( error.response.data ){

                                vm.setError( error.response.data.errors ).then(res=>{
                                    const $focus =  $(':input.form-control.is-invalid,:input.custom-select.is-invalid,.radio-wrap.is-error,.checkbox-wrap.is-error').first();

                                    if( $focus.length ){

                                        let offset = $focus.parent().offset().top;
                                        var body = $("html, body");
                                        body.stop().animate({scrollTop: offset}, 500, 'swing', function() {

                                            if( $focus.get(0).nodeName=='INPUT' ){
                                                $focus.focus();
                                            }
                                        });
                                    }
                                });
                            }
                        } else if (error.request) {
                            // console.log("request: ", error.request)
                        }
                        else{

                            vm.$swal(
                                'เกิดข้อผิดพลาด!',
                                'กรุณาลองใหม่อีกครั้ง!',
                                'error'
                            );

                            // console.log('Error', error.message);
                        }

                        // console.error('error:', error)
                    })
                } catch (error) {

                    vm.$swal(
                        'เกิดข้อผิดพลาด!',
                        'กรุณาลองใหม่อีกครั้ง!',
                        'error'
                    );
                    // alert( 'เกิดข้อผิดพลาด' );
                }
            }, 100);
        },

        setError( errors ){
            const vm = this;

            return new Promise((resolve, reject) => {
                $.each(errors, function (name, message) {

                    vm.errors[ name ] = message[0];
                    // console.log( name, message );
                });

                resolve();
            });
        },

        easter(evt){

            evt = evt ? evt : window.event;

            const key = evt.metaKey || evt.altKey || 0;

            // alt & shift
            if( key && evt.shiftKey){

                var chars = 'abcdefghijklmnopqrstuvwxyz1234567890';
                var string = '';
                for(var ii=0; ii<15; ii++){
                    string += chars[Math.floor(Math.random() * chars.length)];
                }

                this.member.email = string+'@testemail.mail';
                this.member.password = 'password123456aa';
                this.member.password_confirmation = 'password123456aa';
                this.member.prefix = 'นาย';
                this.member.first_name = 'บุญส่ง';
                this.member.last_name = 'ช่วยสุข';

                his.member.first_name_en = 'Teest';
                this.member.last_name_en = 'Teest 2';
                this.member.lineid = 'teest2';

                this.member.gender = 1;

                this.member.birthdate.year = 1990;
                this.member.birthdate.month = '07';
                this.member.birthdate.date = '07';

                this.member.show_idcrad = 1;

                let min = 1000000000000;
                let max = 9999999999999;
                this.member.idcrad =  Math.floor(Math.random() * (max - min + 1)) + min;

                this.member.tel_mobile = '0843635952';
                this.member.facebook = '';

                this.member.occupation_id = 1;

                this.member.affiliation_id = 1;
                this.member.teachings = [1,2,3];
                this.member.levels = [1,2,3];

                this.member.work_id = 1;
                this.member.work_name = 'มหาวิทยาลัยเกษตร';

                this.member.work_province_id = 1;
                this.member.work_amphure_id = 1;
                this.member.work_district_id = 100101;
                this.member.work_zip = 10200;

                // ---
                // this.member.university_faculty = 'วิทยาการเพื่อการเกษตร';
                // this.member.university_name = 'มหาวิทยาลัยมหิดล';
                // this.member.university_year = '4';

                // ระบุอาชีพ
                // this.member.occupation_career = 'เกษตรกร';


                // --------------------------
                this.member.subscription = 1;
                this.member.channel = [1,2,3];


                // --------------------------
                this.member.agree = true;
            }
        },

        str_month(n){
            const months = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
            // const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            return months[n] || '';
        },

        setProvince(){
            this.amphures = [];

            if( this.provinces.length && this.member.work_province_id ){
                const province = this.provinces.find(n=>n.id==this.member.work_province_id);

                if( province.amphures ){
                    this.amphures = province.amphures;

                    const amphure = this.amphures.find(n=>n.id==this.member.work_amphure_id);

                    if( !amphure ){
                        this.member.work_amphure_id = '';
                    }
                }
            }
            else{
                this.districts = [];
                this.member.work_zip = '';
                this.member.work_amphure_id = '';
            }
        },

        setDistrict(){

            this.districts = [];
            this.member.work_zip = '';

            if( this.amphures.length && this.member.work_amphure_id ){
                const amphure = this.amphures.find(n=>n.id==this.member.work_amphure_id);

                if( amphure.districts ){
                    this.districts = amphure.districts;


                    const district = this.districts.find(n=>n.id==this.member.work_district_id);
                    if( !district ){
                        this.member.work_district_id = '';
                    }
                }
            }
            else{
                this.member.work_district_id = '';
            }
        },

        setSubDistrict(){
            if( this.districts.length && this.member.work_district_id ){
                const district = this.districts.find(n=>n.id==this.member.work_district_id);
                this.member.work_zip = district.zip_code
            }
            else{
                this.member.work_zip
            }
        },


        HandlerOccupation(){

            if( this.member.occupation_id == '' || this.member.occupation_id == 10 || this.member.occupation_id == 11 ){

                this.member.affiliation_id = '';
                this.member.teachings = [];
                this.member.levels = [];

                this.member.work_id = '';
                this.member.work_name = '';

                this.member.work_province_id = '';
                this.member.work_amphure_id = '';
                this.member.work_district_id = '';
                this.member.work_zip = '';
            }
        }
    }

};
</script>
