<template>

    <div class="regis-container">
        <div class="mb-5"></div>

        <div class="container">
            <!-- <div class="px-3 py-3 py-md-5 mx-auto text-center">
                <h1 class="display-6">ลงทะเบียนกิจกรรม</h1>
                <p class="lead text-muted mx-auto w-max-700">
                    The Power of Learning Community: พลังของชุมชนแห่งการเรียนรู้
                </p>
            </div> -->

            <!-- step 0 -->
            <div v-if="step == 0">
                <Register
                    :member="member"
                    @update="updateMember"
                    @updateAmphure="updateAmphure"

                    :occupations="occupations"
                    :affiliations="affiliations"
                    :teachings="teachings"
                    :subjects="subjects"
                    :officeAddress="officeAddress"
                    :provinces="provinces"

                    :channels="channels"

                    :prefix="prefix"
                />
            </div>

            <!-- end: step 0 -->
            <div v-else-if="step == 1">
                <Checkout
                    @prev="prev"
                    @submit="submit"
                    :payment_method="payment_method"
                    @method="setPaymentMethod"
                    :loading="loading"
                    :member="member"

                    :occupations="occupations"
                    :affiliations="affiliations"
                    :teachings="teachings"
                    :subjects="subjects"
                    :officeAddress="officeAddress"

                    :provinces="provinces"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Register from "./Register";
import Checkout from "./Checkout";

const occupations = [
    {id: 1, name: 'School Director / Principal (ผู้อำนวยการ / ครูใหญ่)'},
    {id: 2, name: 'Deputy Principal (รองผู้อำนวยการ / ผู้ช่วยผู้อำนวยการ)'},
    {id: 3, name: 'Head Teacher (หัวหน้ากลุ่มสาระ)'},
    {id: 4, name: 'Teacher (ครู)'},
    {id: 5, name: 'Supervisor (ศึกษานิเทศก์)'},
    {id: 6, name: 'Academician / Researcher (นักวิชาการ / นักวิจัย)'},
    {id: 7, name: 'Lecturer (อาจารย์มหาวิทยาลัย)'},
    {id: 8, name: 'School Committee Members (กรรมการสถานศึกษา)'},
    {id: 9, name: 'Student (นักศึกษา)'},
    {id: 10, name: 'Parents / General Public (ผู้ปกครองและประชาชนทั่วไป)'},
    {id: 11, name: 'Others (อื่น ๆ)'},
];

// -----------
const affiliations = [
    {id: 1, name: 'Office of the Higher Education Commission: OHEC (สำนักงานคณะกรรมการการอุดมศึกษา)'},
    {id: 2, name: 'Office of the Basic Education Commission: OBEC (สำนักงานคณะกรรมการการศึกษาขั้นพื้นฐาน)'},
    {id: 3, name: 'Office of the Vocational Education Commission: OVEC (สำนักงานคณะกรรมการการอาชีวศึกษา)'},
    {id: 4, name: 'Office of the Private Education Commission: OPEC (สำนักบริหารงานคณะกรรมการส่งเสริมการศึกษาเอกชน)'},
    {id: 5, name: 'Department of Education BMA (สำนักการศึกษากรุงเทพมหานคร)'},
    {id: 6, name: 'Department of Local Administration (องค์การปกครองส่วนท้องถิ่น)'},
    {id: 7, name: 'Border Patrol Police (ตชด)'},
    {id: 8, name: 'Others (อื่น ๆ)'},
];

// -----------
const teachings = [
    {id: 1, name: 'Kindergarten (ปฐมวัย)'},
    {id: 4, name: 'Senior Secondary (มัธยมศึกษาตอนปลาย)'},
    {id: 2, name: 'Elementary (ประถมศึกษา)'},
    {id: 5, name: 'Vocational Education (อาชีวศึกษา)'},
    {id: 3, name: 'Junior Secondary (มัธยมศึกษาตอนต้น)'},
    {id: 6, name: 'Higher Education (อุดมศึกษา)'},
    {id: 7, name: 'Others (อื่น ๆ)'},
];

// -----------
const subjects = [
    {id: 1, name: 'Thai Language (ภาษาไทย)'},
    {id: 2, name: 'Student Activities (กิจกรรมพัฒนาผู้เรียน)'},
    {id: 3, name: 'Mathematics (คณิตศาสตร์)'},
    {id: 4, name: 'Special Education (การศึกษาพิเศษ)'},
    {id: 5, name: 'Science (วิทยาศาสตร์)'},
    {id: 6, name: 'Occupations and Technology (การงานอาชีพและเทคโนโลยี)'},
    {id: 7, name: 'Arts (ศิลปะ)'},
    {id: 8, name: 'Social Studies, Religious and Culture (สังคม ศาสนา และวัฒนธรรม)'},
    {id: 9, name: 'Kindergarten (ปฐมวัย)'},
    {id: 10, name: 'Health Education and Physical Education (สุขศึกษาและพลศึกษา)'},
    {id: 11, name: 'Counseling (แนะแนว)'},
    {id: 12, name: 'Occupations and Technology (การงานอาชีพและเทคโนโลย)'},
    {id: 13, name: 'Administration (ด้านการบริหาร)'},
    {id: 14, name: 'Foreign Language (ภาษาต่างประเทศ)'},
    {id: 15, name: 'Others (อื่น ๆ)'},
];


// -----------
const officeAddress  = [
    {id: 1, name: 'School (โรงเรียน)'},
    {id: 2, name: 'College (วิทยาลัย)'},
    {id: 3, name: 'University (มหาวิทยาลัย)'},
    {id: 4, name: 'Secondary Education Service Area (สพม.)'},
    {id: 5, name: 'Primary Educational Service Area (สพป.)'},
    {id: 6, name: 'Other Please Identify (อื่น ๆ โปรดระบุ)'},
];

// -----------
const channels  = [
    { id: 1, name: 'Email' },
    { id: 2, name: 'Line' },
    { id: 3, name: 'SMS' },
];

// -----------
const prefix  = [
    { id: 'นาย', name: 'นาย' },
    { id: 'นาง', name: 'นาง' },
    { id: 'นางสาว', name: 'นางสาว' },
    { id: 0, name: 'อื่น ๆ' },
];



export default {
    components: {
        Register,
        Checkout
    },
    data() {
        return {
            step: 0,
            payment_method: 1,

            member: {
                email: "",
                // password: "",
                // password_confirmation: "",

                prefix: "",
                first_name: "",
                last_name: "",

                first_name_en: "",
                last_name_en: "",

                agree: false,
                gender: false,
                subscription: 1,
                channel: [],

                birthdate: {
                    year: "",
                    month: "",
                    date: ""
                },

                show_idcrad: 1,
                idcrad: "",

                passport: "",
                tel_mobile: "",

                facebook: "",

                occupation_id: "",
                occupation_custom: "",

                affiliation_id: "",
                affiliation_custom: "",

                teachings: [],
                other_teaching_group: "",

                levels: [],
                other_teaching_level: "",


                work_id: "",
                work_name: "",
                work_province_id: "",
                work_amphure_id: "",
                work_district_id: "",
                work_zip: "",

                lineid: "",
            },

            provinces: [],
            // amphures: [],
            // districts: [],

            loading: false,

            occupations: occupations,
            affiliations: affiliations,
            teachings: teachings,
            subjects: subjects,
            officeAddress: officeAddress,
            channels: channels,

            prefix: prefix,
        };
    },

    created() {

        this.getUser();
        this.getProvince();

        // console.log( this.token );
    },
    methods: {
        getUser(){
            const vm = this;

            return axios
                .get("/apis/auth/account", {})
                .then(response => {
                    vm.loading = false;

                    vm.member = response.data;
                })
                .catch(error => {
                    vm.loading = false;
                });
        },

        submit() {
            const vm = this;
            vm.loading = true;

            axios
                .post("/api/events/registration", { member: vm.member })
                .then(response => {
                    vm.loading = false;

                    if( response.data.success ){


                        this.$swal(
                            "ลงทะเบียนสำเร็จแล้ว!",
                            "กรุณารอรับผลตอบกลับจากอีเมล!",
                            "success"
                        ).then(result => {
                            // console.log( result );

                            window.location.href = response.data.redirect || '/login';
                        });
                    }
                    else{
                        this.$swal(
                            "ลงทะเบียนล้มเหลว!",
                            "กรุณาลองใหม่อีกครั้ง!",
                            "error"
                        );
                    }
                })
                .catch(error => {
                    vm.loading = false;

                    this.$swal(
                        "ลงทะเบียนล้มเหลว!",
                        "กรุณาลองใหม่อีกครั้ง!",
                        "error"
                    );
                });
        },

        updateMember(data) {
            this.member = data;
            this.step = 1;

            window.scrollTo(0,0);
        },
        updateAmphure(data) {
            this.amphures = data;
        },

        prev() {
            // console.log( 'prev..' );
            this.step = this.step - 1;
        },

        setPaymentMethod(method) {
            this.payment_method = method;
        },

        getProvince() {
            const vm = this;

            axios
                .get("/api/locations/provinces")
                .then(response => {
                    vm.provinces = response.data;
                })
                .catch(error => {});
        }
    }
};
</script>
