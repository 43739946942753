window.elementInViewport =  el => {
    var rect = el.getBoundingClientRect()

    return (
        rect.top    >= 0
     && rect.left   >= 0
     && rect.top <= (window.innerHeight || document.documentElement.clientHeight)
    )
};
window.processScroll = (e) => {

    counter();
};


//
window.counter = () => {
    const $counter = $('#counter')
    if( $counter.length ){

        if( elementInViewport($counter[0]) && !$counter.hasClass('loaded') ){

            $counter.addClass('loaded');
            $counter.find('[aria-counter]').each(function() {

                $(this).prop('Counter', 0).animate({
                    Counter: $(this).text()
                }, {
                    duration: 800,
                    easing: 'swing',
                    step: function(now) {
                        $(this).text(Math.ceil(now));
                    }
                });
            });
        }
    }
}

$(function () {

    $(window).scroll(processScroll)
    processScroll();


    $('img.lazy').lazy();


})
