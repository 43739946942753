require('./bootstrap');

window.Vue = require('vue');
import VueSweetalert2 from 'vue-sweetalert2';
import NavbarComponenet from './components/NavbarComponenet'
import RegistrationComponent from './components/RegistrationComponent'
import fullscreen from 'vue-fullscreen'
import VueYouTubeEmbed from 'vue-youtube-embed'

Vue.use(VueSweetalert2, {
    // confirmButtonColor: '#41b882',
    confirmButtonColor: '#224368',
    cancelButtonColor: '#ebebeb',
});
Vue.use(fullscreen)
Vue.use(VueYouTubeEmbed)

const RegisterComponent = () => import('./components/RegisterComponent/index.vue')
const SettingAccount = () => import('./components/SettingAccount/index.vue')
const PostFilter = () => import('./components/PostFilter/index.vue')
const PostFeatured = () => import('./components/PostFeatured/index.vue')
const VideoLearning = () => import('./components/VideoLearning/index.vue')
const ReviewComment = () => import('./components/ReviewComment/index.vue')

const SettingName = () => import('./components/SettingName/index.vue')

const LearningVideoWatch = () => import('./components/LearningVideoWatch/index.vue')
const VideoConference = () => import('./components/VideoConference/index.vue')

new Vue({
    el: '#app',
    // router,
    // store,
    components: {
        NavbarComponenet,
        RegisterComponent,

        SettingAccount,
        RegistrationComponent,
        PostFilter,
        PostFeatured,

        VideoLearning,
        ReviewComment,
        SettingName,

        LearningVideoWatch,
        VideoConference,
    },

});
