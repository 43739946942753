
export const routes = [
    {
        path: '/',
        name: 'Home',
        cls: 'home',
        icon: '<i class="fa fa-home"></i>',
    },
    {
        path: '/workshops',
        name: 'Workshop',
    },
    {
        path: '/',
        name: 'Special Session',

        children: [
            {
                 // path: '/international-conference',
                path: '/conferences',
                name: 'International Conference'
            },
            {
                path: '/principal-forum',
                name: 'Principal Forum',
            },
            // {
            //     path: '/learnings/66',
            //     name: 'Teacher Ed Forum',
            // },
            // {
            //     path: '/',
            //     name: 'Document',
            // },
        ]
    },
    {
        path: '/',
        name: 'Marketplace',
    },
    {
        path: '/sponsors',
        name: 'Sponsor',

    },
];
