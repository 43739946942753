<template>
<div class="mb-5">

    <div class="regis-title mb-4 d-flex align-items-center">
        <h4>Summary Profile</h4>
    </div>

    <div class="row justify-content-center">
        <div class="col-md-8 mb-4">
            <div class="card border-0">
                <div class="">

                    <table class="profile-table">
                        <tbody>

                            <template v-for="(summary, index) in summaries">
                            <tr v-if="index < 5 || showall" :key="index">
                                <td class="text-muted td-label">{{summary[0]}}</td>
                                <td class="pl-3"><strong>{{summary[1]}}</strong></td>
                            </tr>
                            </template>
                        </tbody>
                    </table>

                    <div v-if="!showall" class="mt-3 d-flex justify-content-center">
                        <button type="button" class="btn btn-outline-secondary w-min-120" @click="showall=true">Show more</button>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="regis-title mb-4 d-flex align-items-center">
        <h4>Payment Method</h4>
    </div>
    <div class="row justify-content-center">

        <div class="col-md-8 my-4">

            <div class="font-weight-bold">ชำระค่าลงทะเบียน เข้าร่วมงาน EDUCA 2020 จำนวนเงิน 100.00 บาท</div>
            <div class="font-weight-bold">ธนาคารกสิกรไทย-สาขา เมกาบางนา 731-1-02643-1</div>

            <div><a href="https://educathai.com/knowledge/faq/41" target="_blank"><u>วิธีการชำระค่าลงทะเบียน</u></a> เข้าร่วมงาน EDUCA 2020 </div>
        </div>

    </div>


    <div class="row my-4 justify-content-center">

        <div class="col-lg-9">
            <div class="d-flex justify-content-center py-5">

                <button type="button" class="btn btn-light btn-lg w-min-120 mr-5 font-weight-bold text-white" @click="prev">Edit</button>

                <button :disabled="loading" type="button" class="btn btn-primary btn-lg w-min-120 font-weight-bold" @click="submit">Confirm</button>

            </div>
        </div>
    </div>

</div>

</template>

<script>
export default {
    props: [
        'payment_method',
        'loading',
        'member',

        'occupations',
        'affiliations',
        'teachings',
        'subjects',
        'officeAddress',

        'provinces',
    ],
    data(){
        return{
            method: 1,

            summaries: [],

            showall: false
        }
    },

    created(){
        this.method = this.payment_method


        let prefix = this.member.prefix;
        if( prefix===0 ){
            prefix = this.member.prefix_custom
        }

        this.summaries.push(['Email / อีเมลสมาชิก', this.member.email])
        this.summaries.push(['Name - Surname / ชื่อ-นามสกุล', prefix + ' ' + this.member.first_name + ' ' + this.member.last_name])

        this.summaries.push(['Date of Birth / วันเกิด', this.member.birthdate.date + ' / ' + this.member.birthdate.month + ' / ' + this.member.birthdate.year])

        if( this.member.is_idcrad ){
            this.summaries.push(['Identification No. / หมายเลขบัตรประจำตัว', this.member.idcrad])
        }

        if( this.member.is_passport ){
            this.summaries.push(['Passport / หมายเลขพาสปอร์ต', this.member.passport])
        }


        this.summaries.push(['Mobile No. / เบอร์ติดต่อ', this.member.tel_mobile])
        this.summaries.push(['Facebook / ชื่อเฟสบุ๊ค', this.member.facebook])

        const occupation = this.occupations.find(n=>n.id==this.member.occupation_id);
        let occupationName = occupation.name;

        if( this.member.occupation_id == 10 || this.member.occupation_id == 11 ){
            occupationName = this.member.occupation_custom;
        }

        this.summaries.push(['Occupation or Poition / อาชีพหรือตำแหน่ง', occupationName])


        if( this.member.occupation_id >0 && this.member.occupation_id <=8 ){

            const affiliation = this.affiliations.find(n=>n.id==this.member.affiliation_id);

            let affiliationName = affiliation.name;
            if( this.member.affiliation_id == 8 ){
                affiliationName = this.member.affiliation_custom;
            }

            this.summaries.push(['Affiliation / สังกัด', affiliationName])


            let str_teaching = '';
            this.member.teachings.forEach(i => {
                const obj = this.teachings.find(n=>n.id==i);

                let objName = obj.name
                if(obj.id==7){
                    objName = this.member.other_teaching_group
                }

                str_teaching += str_teaching=='' ? '':', ';
                str_teaching += objName;
            });
            this.summaries.push(['Teaching Level / ระดับชั้นที่สอนหรือเกี่ยวข้อง', str_teaching])


            let str_subject = '';
            this.member.levels.forEach(i => {
                const obj = this.subjects.find(n=>n.id==i);

                let objName = obj.name
                if(obj.id==14){
                    objName += ' '+this.member.foreign_language
                }

                if(obj.id==15){
                    objName = this.member.other_teaching_level
                }

                str_subject += str_subject=='' ? '':', ';
                str_subject += objName;
            });

            this.summaries.push(['Subject or Related Field / กลุ่มสาระที่สอนหรือทำงานเกี่ยวข้อง', str_subject])

            const address = this.officeAddress.find(n=>n.id==this.member.work_id);

            this.summaries.push(['Office Address / สถานที่ทำงาน', address.name])
            this.summaries.push(['School or Institution / ชื่อสถาบัน', this.member.work_name])

            const province = this.provinces.find(n=>n.id==this.member.work_province_id);

            const amphure = province.amphures.find(n=>n.id==this.member.work_amphure_id);
            const district = amphure.districts.find(n=>n.id==this.member.work_district_id);

            this.summaries.push(['Province / จังหวัด', province.name_en + ' / ' + province.name_th])
            this.summaries.push(['District / อำเภอหรือเขต', amphure.name_en + ' / ' + amphure.name_th])
            this.summaries.push(['Sub District / ตำบลหรือแขวง', district.name_en + ' / ' + district.name_th])
            this.summaries.push(['Postal Code / รหัสไปรษณีย์', this.member.work_zip])
        }


        if( this.member.occupation_id==9 ){

            this.summaries.push(['Faculty / คณะ', this.member.university_faculty])
            this.summaries.push(['University / มหาวิทยาลัย', this.member.university_name])
            this.summaries.push(['Year / ชั้นปี', this.member.university_year])
        }

        if( this.member.occupation_id==10 && this.member.occupation_id==11 ){

            // this.summaries.push(['Year / ชั้นปี', this.member.university_year])
        }

    },

    watch: {
        method(){
            this.$emit('method', this.method);
        },
    },
    methods: {
        prev( e ){
            e.preventDefault();

            window.scrollTo(0,0);

            this.$emit('prev', e);
        },

        submit(){

            this.$emit('submit');
        }
    }
}
</script>

<style lang="scss" scoped>
.profile-table{
    width: 100%;

    td{
        padding-top: 10px;
        padding-bottom: 10px;
        border-width: 1px 0;
        border-style: dotted;
        border-color: #eee;

        text-align: left;
    }

    .td-label{
        font-weight: bold;
        width: 35%;;
    }
}
</style>
